.bglogo {
  text-align: center;
  margin: 5px;
}

.bglogo img {
  width: calc(100% - 50px);
  text-align: center;
  margin: 0 auto;
  /* filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1); */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

b,
strong {
  font-weight: bold !important;
}

.background-white.card-header {
  background-color: #fff;
}

.background-white.card-title {
  color: #000;
}

.card.card-login h3 {
  margin: 15px 0px 0px;
  font-size: 33px;
}

.card.card-login .card-body {
  padding: 15px 20px 15 20;
}

.card.card-login img {
  width: calc(100% - 100px);
  text-align: center;
  margin: 0 auto;
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* .sidebar-wrapper>ul.nav>li.nav-item:last-child {
  display: none;
} */
li.nav-item .hidden.nav-link {
  display: none;
}

.float-left {
  float: left;
}

.col-md-6 .fa-file {
  font-size: 35px;
  margin: 10px;
}

.card {
  border: none;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.pr-1,
.pl-1 {
  margin-bottom: 10px;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.clustering .ReactTable .col-12 {
  width: 100% !important;
}

.react-select__single-value {
  position: absolute !important;
  top: 5px !important;
}

.react-select__placeholder {
  position: absolute;
}

.react-select__control {
  position: relative;
}

.error {
  color: #fb404b !important;
  text-transform: none !important;
  margin-bottom: 0 !important;
  font-size: 12px;
}

.has-success .form-control:focus {
  border-color: #87cb16;
  color: #87cb16;
}

.has-error .form-control:focus {
  border-color: #fb404b;
}

.clustering h4,
.clustering button.btn-default {
  display: inline-block;
  width: calc(100% / 2 - 30px);
  margin: 15px;
}

.clustering .card {
  display: block !important;
}

.clustering .ReactTable .col-12 {
  width: 100% !important;
}

.clustering .react-select__menu {
  width: 192px !important;
}

.clustering
  .-center
  .justify-content-center
  .col-12.col-sm-6.col-md-6:last-child {
  display: none;
}

.clustering .-center .justify-content-center > .col-12.col-sm-6.col-md-6 {
  max-width: 100%;
  flex: 0 0 100% !important;
}

.hrSweet {
  border-top: solid #23ccef 4px;
  margin: 10px 0;
  padding-top: 10px;
}

.ct-chart.ct-perfect-fourth {
  width: 40%;
  margin: 0 auto !important;
}

#hiddenBtn {
  display: none;
}

#loaderTable {
  margin: 0 auto;
  width: 50px;
  height: auto;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #17a2b8;
  background-color: #fff;
  color: #17a2b8;
  margin-bottom: 20px;
  cursor: pointer;
}

.dropzone:hover {
  border: 3px dashed #0fcae7;
  background-color: #fff;
  color: #0fcae7;
}

.imgUpload {
  width: 200px;
  margin-left: 20px;
}

.iconeUpload {
  width: 32px;
  margin-left: 20px;
}

.page404.container-fluid {
  text-align: center;
  padding-top: 50px;
  font-size: 60px;
  color: red;
}

/* .nav-item:hover a.nav-link, .nav-item:hover a.nav-link i , .nav-item:hover a.nav-link p {
  color: white !important;
} */

.nav-item {
  cursor: pointer;
  border-radius: 4px;
  line-height: 2;
  /* background: #bb122c; */
  width: calc(100% - 10px);
  margin: 0 auto;
  margin-bottom: 2px;
}

.eval-title {
  color: #bb122c;
  font-weight: bold;
  font-size: 26px;
  padding-left: 10px;
  margin-top: 50px;
}

.btn-val {
  width: 140px;
  margin: 0 auto;
  padding: 10px;
  font-size: 20px;
}

.green-button {
  background-color: green;
  border: green;
  color: white;
}

.green-button,
.green-button:hover,
.green-button:active,
.green-button:focus {
  background-color: green;
  border: green;
  color: white;
  outline: none;
}

.green-button:active {
  background-color: green !important;
}

.dropdown-menu.show {
  left: -60px;
}

.navbar-nav .nav-item .dropdown-menu:before,
.navbar-nav .nav-item .dropdown-menu:after {
  content: none !important;
}

.demande {
  text-align: center;
  padding: 10px;
}

.rbc-calendar {
  height: auto !important;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #000;
  line-height: initial !important;
}

.dropdown-item {
  font-size: 14px !important;
}

.dropdown-item.nonlu {
  background-color: #cccc;
}

.demande {
  width: 50%;
  margin: 0 auto;
}

.demande .fa-file {
  width: 100%;
  font-size: 100px;
}

.hidden {
  display: none;
}

.styeleHeader {
  font-size: 20px;
  text-align: center;
  line-height: 3;
  color: #fff;
}

.chronoStyle {
  width: "500px";
  height: "400px";
}

.css-ouzaa4-TimelineContentDetailsWrapper.show-less {
  max-height: initial !important;
}

.css-12yy2iw-TimelineItemContentWrapper {
  min-height: auto !important;
}

.sl-bubble1 {
  float: right !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  z-index: 100000 !important;
  background-color: #fff;
  overflow: hidden;
}

.font30 {
  font-size: 30px;
  margin: 15px 0;
}

.messagerie .nav {
  flex-direction: column;
  margin-top: 16px;
}

.messagerie .nav .nav-item .nav-link:hover,
.messagerie .nav .nav-item .nav-link:focus,
.messagerie .nav-tabs .nav-item.show .nav-link,
.messagerie .nav-tabs .nav-link.active {
  background-color: #23ccef;
  color: #fff;
  opacity: 1;
}

.messagerie .nav-tabs .nav-item {
  /* margin-bottom: -1px; */
  border: 1px solid #ddd;
  background: #fff;
  margin-bottom: 5px;
}

.messagerie .nav-tabs .nav-link {
  opacity: 0.5;
}

.panel-default {
  border-color: #ddd;
}

.panel {
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.panel-default > .panel-heading {
  color: #ffffff;
  background-color: #23ccef;
  border-color: #23ccef;
}

.panel .panel-heading {
  padding: 10px;
}

.panel-body {
  box-shadow: inset 1px 0px 5px 0px #0000001f;
  background: #fff;
  padding: 20px;
  height: 300px;
  overflow-x: scroll;
}

.messagerie button {
  float: right;
  padding: 0;
  line-height: 2;
  width: 50px;
  border-radius: 0;
  background: transparent;
  border: none;
  color: #23ccef;
  font-size: 20px;
  padding-top: 5px;
  margin: 0;
}

.messagerie input,
.messagerie textarea {
  width: calc(100% - 50px);
  float: left;
  background: transparent;
  border: none;
}

.input-msg {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: 1px solid #ddd;
  background: #fff;
}

.clear {
  clear: both;
  display: block;
}

.discutions-date {
  color: #9a9797;
  font-size: 12px;
}

.form-control:focus {
  border: none !important;
}

.enteteDropDown,
.enteteDropDown:hover {
  background-color: #23ccef !important;
  border-bottom-color: #bcd4e5;
  color: #fff !important;
  box-shadow: 1px 1px 1px 1px #ddd;
  font-size: 13px !important;
}

.footerDropDown {
  border-top: 1px solid #e4ecf3;
  background-color: #23ccef !important;
  border-top-color: #bcd4e5;
  color: #fff !important;
  box-shadow: 1px 1px 1px 1px #ddd;
  font-size: 13px !important;
}

.navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  border-radius: 0 !important;
}

a.dropdown-item {
  font-size: 13px !important;
}

.dropdown-msg .dropdown-menu,
.dropdown-notification .dropdown-menu {
  min-width: 13rem !important;
}

.sidebar .sidebar-wrapper .nav .nav-link p {
  font-weight: bold !important;
  color: #000;
  /* font-family: cursive; */
  font-size: 13px;
}

.sidebar .sidebar-wrapper .nav .nav-link p:hover {
  color: #fff;
}

.sidebar .sidebar-wrapper .nav .nav-item.active .nav-link p {
  font-weight: bold !important;
  color: #fff;
  /* font-family: cursive; */
  font-size: 13px;
}

#saveAdd {
  background: #bb122c;
  border-color: #bb122c;
}

#returnBtn {
  background: #bb122c;
  border-color: #bb122c;
}

#returnSpan {
  color: white;
}

.sidebar .nav .nav-item .nav-link i {
  font-size: 25px !important;
  width: 22px !important;
}

/* .sidebar .nav .nav-item .nav-link {
  margin: 5px 0px 0px 10px !important;
} */

a.dropdown-item {
  white-space: break-spaces;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #bb122c;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.pagination {
  margin-top: 20px;
}

.pagination > li > a {
  border-radius: initial !important;
  background-color: #fff;
  border: 1px solid #23ccef !important;
  color: #23ccef !important;
  padding: 7px 13px;
  margin-right: 5px !important;
}

.pagination > li.active > a {
  color: #fff !important;
}

.fileUrl {
  display: block;
  margin: 17px;
}

.fileUrl .fas {
  font-size: 38px;
}

.loaderTable {
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 90px;
}

.textLoader {
  text-align: center;
  margin-top: 15px;
  color: red;
  font-size: 20px;
}

.dropdown-profile .dropdown-menu {
  min-width: 150px !important;
}

.btn-question {
  font-size: 20px;
}

.table-question thead.rt-thead.-header input {
  display: none !important;
}

.form-check-radio {
  padding: 0;
}

.questionUser h2 {
  margin: 0;
}

/* responsive tablet mini */
@media (max-width: 1000px) {
  .nav-tabs .nav-item {
    font-size: 14px;
  }

  #root .navbar-nav {
    flex-direction: revert;
  }

  .panel-body .discutions {
    font-size: 13px;
  }

  .dropdown-msg .dropdown-menu,
  .dropdown-notification .dropdown-menu {
    min-width: 5em !important;
  }

  .rbc-toolbar .rbc-toolbar-label {
    text-align: left !important;
    padding-left: 35px !important;
  }

  .rbc-toolbar .rbc-btn-group {
    text-align: left !important;
  }

  .calendar-header .card-body,
  .card-header > .card-body {
    /* width: 1000px; */
  }

  .col-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .demande.card {
    width: 100%;
  }

  .card-wizard .moving-tab {
    line-height: 35px !important;
  }
}

/* responsive mobile */
@media (max-width: 600px) {
  .card-wizard .moving-tab {
    width: 100% !important;
    line-height: 18px !important;
  }

  .card-wizard .nav-pills > li {
    width: 100% !important;
  }

  .pr-1,
  .pl-1 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/* new css */
.sweet-alert ul {
  list-style: none;
  line-height: 2;
  font-size: 26px;
  text-align: left;
  padding-left: 30px;
}

.full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.full-page[data-color="black"]:after {
  background: #282828;
  background: linear-gradient(180deg, #282828, rgba(17, 17, 17, 0.4));
  opacity: 0.9;
}

/* .full-page[data-color=black]:after {
  background: #797979;
  background: linear-gradient(180deg,#797979 0,#797979);
  background-size: 150% 150%;
} */
.full-page:after,
.full-page:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.full-page > .content {
  min-height: calc(100vh - 70px);
  z-index: 4;
  position: relative;
}

.btn-dark {
  border: 1px solid #000 !important;
  background-color: #000 !important;
  font-size: 20px;
  /* float: right; */
}

.btn-coach {
  border: 1px solid #000 !important;
  background-color: #000 !important;
}

.btn-coachFlm {
  border: 1px solid #f32213 !important;
  background-color: #f32213 !important;
}

.font-size {
  font-size: 20px;
}

.full-page:before {
  opacity: 0.33;
  background: #000;
}

.card.card-login {
  background: linear-gradient(180deg, #a50019, #4f0500) !important;
}

.sidebar {
  box-shadow: -1px 0px 7px 1px #00000085;
}

.spanLogin {
  color: white;
}

.margin-bottom {
  margin-bottom: 20px;
}

.card.card-login label {
  color: #fff;
}

.full-gray {
  background-image: url(../img/coaching.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.full-gray .align-items-center {
  height: 100%;
}

.card.card-login .card-header {
  background-color: initial;
  color: #fff;
  padding: 0;
}

/* F9F9F9 */

.sidebar[data-color="black"]:after {
  background: #282828 !important;
  background: linear-gradient(180deg, #f9f9f9, #f9f9f9) !important;
  background-size: 150% 150%;
}

.sidebar.stagiaire[data-color="black"]:after {
  background: linear-gradient(180deg, #25a730, #142f009c) !important;
}

.sidebar.encadrant[data-color="black"]:after {
  background: linear-gradient(180deg, #252ea7, #142f009c) !important;
}

.sidebar.chef[data-color="black"]:after {
  background: linear-gradient(180deg, #1f245d, #142f009c) !important;
}

.card.card-login .btn {
  color: #0d1020;
  background: #fff;
  border: none;
}

.sidebar:before,
body > .navbar-collapse:before {
  opacity: 1;
}

.Toastify__toast--success {
  background: #07bc0c !important;
  color: white !important;
}

.Toastify__progress-bar--success,
.Toastify__progress-bar--error {
  background: rgba(255, 255, 255, 0.7) !important;
}

.Toastify__toast-body strong {
  color: white;
  font-size: 14px !important;
}

.Toastify__toast--error {
  background-color: #e74c3c !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast-body i {
  margin-right: 5px;
}

.sidebar .caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-top: 13px;
  position: absolute;
  right: 0;
  transition: all 0.15s ease-in;
}

.sweet-alert {
  margin: auto !important;
  top: unset !important;
  left: unset !important;
}

.sidebar .logo {
  border: none !important;
}

.sidebar .logo-img img {
  width: 100% !important;
}

.success td {
  background-color: rgba(135, 203, 22, 0.2);
}

.table-parametre {
  width: 1600px;
}

#dropdown-1 {
  padding: 0 !important;
}

.table-parametre td,
.table-parametre th {
  border-right: 1px solid #dee2e6;
}

.table-parametre th,
.table-prod th {
  background-color: #eb4962;
  color: #f9f5f5 !important;
  font-size: 13px !important;
}

.background-orange {
  background-color: #ffbc67;
  color: white;
}

.background-red {
  background-color: #fc727a;
  color: white;
}

.background-yellow {
  background-color: #ffeb3bcf;
  color: white;
}

.background-gray {
  background-color: #9e9e9e;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .scroll {
    overflow-x: scroll;
  }

  .display-none {
    display: none !important;
  }

  .card-header.card {
    overflow: scroll;
  }
}

.image-style {
  width: calc(100% - 20px);
  margin: 0 auto;
}

.text-area {
  height: 150px;
}

.input-msg {
  display: flex;
}

.input-msg input {
  border: none;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}

.carousel-indicators {
  display: none;
  margin-bottom: -20px;
}

.card .card-footer {
  text-align: center;
}

.btn-oublie {
  text-decoration: none;
  font-size: 16px;
  color: #000202;
}

.table-hover .form-check {
  padding-left: 0 !important;
}

.accept-btn {
  float: right;
}

.etat-vad {
  justify-content: center;
  text-align: center;
  display: flex;
  font-size: 22px;
}

.send-tn {
  float: right;
  margin-right: 15px;
}

.discutions-li {
  font-size: 18px;
  margin-left: -40px;
}

.background-detail.card-header {
  border-bottom: none;
  background: #ffffff00;
  border-radius: 0;
  color: #fff;
  padding: 15px;
}

.title-detail.card-title {
  color: #bb122c;
  font-weight: 1000;
  margin: 0;
}

.ul-detaileval {
  padding-left: 1rem;
  font-size: 18px;
  list-style: inside;
}

.li-detaileval {
  font-weight: bold;
  font-size: 16px;
}

.title-grille {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #bb122c;
}

.logout-link-text {
  margin-left: 15px;
  color: #000;
  font-size: 13px;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.logout-link-text i {
  margin-top: -3px;
  font-size: 25px !important;
  width: 22px !important;
  font-weight: bold;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px;
  }
}

.table-prod th:first-child {
  width: 300px;
  text-align: left;
}

.table-prod td:first-child {
  text-align: left;
}

.row-coaching {
  text-align: center;
  display: flex;
  justify-content: center;
}

.row-coaching .col-md-6 .btn-dark {
  float: right;
}

.row-coaching .col-md-6 .font-size {
  float: left;
}

.strong-user {
  margin-top: 35px;
  font-size: 25px;
}

@media (max-width: 1000px) {
  .row-coaching .col-md-6 .btn-dark {
    float: none;
  }

  .row-coaching .col-md-6 .font-size {
    float: none;
  }
}

.annee-width {
  width: 140px;
}

.carousel-control-prev {
  display: none;
}

.carousel-control-next {
  display: none;
}

.loader-gif {
  text-align: center;
}

.img-gif {
  width: 80px;
}

.small-table td {
  font-size: 13px;
}

.small-table {
  width: 70%;
}

.small-table thead th {
  font-size: 10px;
}

.table-responsive {
  max-width: 100%;
  overflow-x: auto;
}

.select-red .react-select__control {
  border-color: red;
}

/* .fa-xmark {
  color: #ff5050;
  font-size: 23px;
}

.fa-check {
  color: #37dd37;
  font-size: 23px;
} */

.td-select {
  padding-left: 15px;
  width: 230px !important;
  /* display: block; */
}

.parag-td {
  font-weight: 500;
  color: #bb122c;
  font-size: 13px;
}

.react-select .react-select__control {
  height: auto !important;
}

.div-date {
  padding: 5px;
}

header-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-sync {
  overflow-x: auto;
  width: 100%;
  height: 20px;
}

.table-scroll {
  max-height: 600px;
  overflow-x: auto;
  white-space: break-spaces;
}

.fixed-header th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
